<header>OpenID4VP-Verifizierung</header>

<div class="main-wrapper container-fluid">

  <strong class="loader-message" *ngIf="showLoader">Bitte warten, während wir den QR-Code vorbereiten</strong>
  <app-waiter *ngIf="showLoader"></app-waiter>

  <div style="display: block;" class="vh-73" *ngIf="credentials.length === 0">
    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="showQrCode">
      <div class="card border-0" style="background-color: #00000000;">
        <app-showqrcode class="qr-code" [verificationUrl]="appUrl"></app-showqrcode>
      </div>
    </div>
  </div>

  <div *ngIf="credentials.length > 0">
    <div *ngFor="let credential of credentials">
      <div *ngIf="credential.length > 0">
        <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
          <div class="card border-0 card_shadow">
            <div class="card-body">

              <div class="row mx-auto mb-3" *ngFor="let prop of credential">
                <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                  <div class="col-elementLabel">
                    <div class="elementValue">
                      <label for="honorific-prefix ">{{credentialPropertyEnums[prop["key"]]}}</label>
                    </div>
                  </div>
                </div>

                <div class="col-lg-8 col-md-8 col-sm-8">
                  <div class="col-elementValue">
                    <div class="elementValue">
                      {{prop["value"]}}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <dialog id="dialog-box-alert">
    <h3>Es ist ein Fehler aufgetreten</h3>
    <p>{{alertMessage}}</p>
    <p *ngIf="hintMessage.length > 0">{{hintMessage}}</p>
    <div class="inline">
      <button (click)="closeMessage()" class="link-box-white">Ok</button>
    </div>
  </dialog>