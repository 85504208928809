import { Inputdescriptor } from "../inputdescriptor.model";
import { Issuer } from "../issuer.model";
import { ValidationRequestPresentationModel } from "./validation-request-presentation.model";

export class IssuanceOfferCredentialShort {
  issuer: Issuer;
  cPersonalized: { [key: string]: any }[];
  requestPresentation?: ValidationRequestPresentationModel;
  useCre: boolean;
  isPaid: boolean;
  isFree: boolean;

  constructor(issuer: Issuer, cPersonalized: { [key: string]: any }[], useCre: boolean, isPaid: boolean, isFree: boolean, inputDescriptors?: ValidationRequestPresentationModel) {
    this.issuer = issuer;
    this.cPersonalized = cPersonalized;
    this.requestPresentation = inputDescriptors;
    this.useCre = useCre;
    this.isPaid = isPaid;
    this.isFree = isFree;
  }

  toJSON() {
    if (this.requestPresentation !== undefined) {
      return {
        issuer: this.issuer,
        cPersonalized: this.cPersonalized,
        requestPresentation: this.requestPresentation,
        useCre: this.useCre,
        isPaid: this.isPaid,
        isFree: this.isFree
      }
    } else {
      return {
        issuer: this.issuer,
        cPersonalized: this.cPersonalized,
        useCre: this.useCre,
        isPaid: this.isPaid,
        isFree: this.isFree
      }
    }
  }
}
