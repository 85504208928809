export class Inputdescriptor {

  type: string = "";
  subtype: string | undefined = undefined

  constructor(type: string, subtype?: string) {
    this.type = type;
    this.subtype = subtype;
  }

  toJSON() {
    if (typeof this.subtype === 'string') {
      return {
        type: this.type,
        subtype: this.subtype
      }
    } else {
      return {
        type: this.type,
      }
    }
  }
}