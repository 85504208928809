import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private config: AppConfig = {
    HOME_URL: "",
    DC_URL: "",
    APP_URL: "",
    CONFIGURATION: "",
    TIMESTAMP: ""
  };
    loaded = false;
    constructor() {}
    loadConfig(): Promise<void> {
        return fetch(window.origin + '/config/config.json').then(response => {
          if (response.body instanceof ReadableStream){
            return (response.json())
          } else {
            return response;
          }
        }).then(data => {
              if(this.isInstanceOfAppConfig(data)) {
                this.config = data;
                this.loaded = true;
              }
            });
    }
  
    getConfig(): AppConfig {
        return this.config;
    }

    isInstanceOfAppConfig(object: any): object is AppConfig {
      let homeUrl = 'HOME_URL' in object;
      let dcUrl = 'DC_URL' in object;
      let appUrl = 'APP_URL' in object;
      let configuration = 'CONFIGURATION' in object;
      let timestamp = 'TIMESTAMP' in object;
      if (homeUrl && dcUrl && appUrl && configuration && timestamp) {
        return true
      } else {
        return false;
      }
    }
}

export interface AppConfig {
  HOME_URL: string;
  DC_URL: string;
  APP_URL: string;
  CONFIGURATION: string;
  TIMESTAMP: string;
}