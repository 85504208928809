<header>Hauptseite</header>

<div class="main-wrapper">
  <div class="home-wrapper container-fluid flex-wrapper">
    <!-- <a [routerLink]="['/credential']" routerLinkActive="active"
      class="link-box link-box-home flex-grow-1 flex-shrink-1 flex-basis-0">
      <p>
        <h3>B&uuml;rgerb&uuml;ro Use Case:</h3>
        <p>
        <h1>Kommunale Datenkarte (KDK)</h1>
    </a> -->
    <a [routerLink]="['/kommpass']" routerLinkActive="active"
      class="link-box link-box-home flex-grow-1 flex-shrink-1 flex-basis-0">
      <p>
        <h3>KommPass Use Case:</h3>
        <p>
        <h1>Kommunaler Pass</h1>
    </a>
    <a [routerLink]="['/wohngeldbescheid']" routerLinkActive="active"
      class="link-box link-box-home flex-grow-1 flex-shrink-1 flex-basis-0">
      <p>
        <h3>Sozialamt Use Case:</h3>
        <p>
        <h1>Wohngeldbescheid</h1>
    </a>
    <a [routerLink]="['/ddp']" routerLinkActive="active"
      class="link-box link-box-home flex-grow-1 flex-shrink-1 flex-basis-0">
      <p>
        <h3>Sozialamt Use Case:</h3>
        <p>
        <h1>Sozialpass</h1>
    </a>
    <a [routerLink]="['/bada']" routerLinkActive="active"
      class="link-box link-box-home flex-grow-1 flex-shrink-1 flex-basis-0">
      <p>
        <h3>Use Case: EU-Bankkonto</h3>
        <p>
        <h1>Eröffnung eines Bankkontos</h1>
    </a>
    <a [routerLink]="['/badalogin']" routerLinkActive="active"
      class="link-box link-box-home flex-grow-1 flex-shrink-1 flex-basis-0">
      <p>
        <h3>Use Case: EU-Bankkonto-Login</h3>
        <p>
        <h1>Anmeldung mit Bank-Verfügungscredentials</h1>
    </a>
    <a [routerLink]="['/sepamandate']" routerLinkActive="active"
      class="link-box link-box-home flex-grow-1 flex-shrink-1 flex-basis-0">
      <p>
        <h3>Use Case: SEPA-Lastschriftmandat</h3>
        <p>
        <h1>Erteilung des SEPA-Lastschriftmandats</h1>
    </a>
    <a [routerLink]="['/mobilecontract']" routerLinkActive="active"
      class="link-box link-box-home flex-grow-1 flex-shrink-1 flex-basis-0">
      <p>
        <h3>Use Case: Mobilfunkvertrag</h3>
        <p>
        <h1>Mobilfunkvertrag abschließen</h1>
    </a>
    <a [routerLink]="['/mobilecontractlogin']" routerLinkActive="active"
      class="link-box link-box-home flex-grow-1 flex-shrink-1 flex-basis-0">
      <p>
        <h3>Use Case: Mobilfunkvertrag-Login</h3>
        <p>
        <h1>Mobilfunkvertrag-Login</h1>
    </a>
   <a [routerLink]="['/nextbike']" routerLinkActive="active"
      class="link-box link-box-home flex-grow-1 flex-shrink-1 flex-basis-0">
      <p>
        <h3>Use Case: Nextbike</h3>
        <p>
        <h1>Nextbike-Vertrag abschließen</h1>
    </a>
    <a [routerLink]="['/drivinglicense']" routerLinkActive="active"
      class="link-box link-box-home flex-grow-1 flex-shrink-1 flex-basis-0">
      <p>
        <h3>Use Case: Führerschein-Nachweis</h3>
        <p>
        <h1>Führerschein-Nachweis ausstellen</h1>
    </a>
    <a [routerLink]="['/teilauto']" routerLinkActive="active"
      class="link-box link-box-home flex-grow-1 flex-shrink-1 flex-basis-0">
      <p>
        <h3>Use Case: teilAuto</h3>
        <p>
        <h1>teilAuto-Vertrag abschließen</h1>
    </a>
    <a [routerLink]="['/jungheinrich']" routerLinkActive="active"
      class="link-box link-box-home flex-grow-1 flex-shrink-1 flex-basis-0">
      <p>
        <h3>Use Case: Jungheinrich</h3>
        <p>
        <h1>Jungheinrich-Vertrag abschließen</h1>
    </a>
    <a [routerLink]="['/ticketshop']" routerLinkActive="active"
      class="link-box link-box-home flex-grow-1 flex-shrink-1 flex-basis-0">
      <p>
        <h3>&Ouml;PNV Use Case:</h3>
        <p>
        <h1>Ticketverkauf</h1>
    </a>
    <!-- <a [routerLink]="['/party-selector/sccVisitorsCredential']" (click)="click('sccVisitorsCredential')"
      routerLinkActive="active" class="link-box link-box-home flex-grow-1 flex-shrink-1 flex-basis-0">
      <p>
        <h3>UseCase Interoperabilität:</h3>
        <p>
        <h1>SCC2023 Besuchernachweis Ausstellung</h1>
    </a> -->
    <!-- <a [routerLink]="['/sccv']" routerLinkActive="active"
      class="link-box link-box-home flex-grow-1 flex-shrink-1 flex-basis-0">
      <p>
        <h3>UseCase Interoperabilität:</h3>
        <p>
        <h1>SCC2023 Besuchernachweis Validierung</h1>
    </a> -->
    <a [routerLink]="['/openid4vplogin']" routerLinkActive="active"
      class="link-box link-box-home flex-grow-1 flex-shrink-1 flex-basis-0">
      <p>
        <h3>Use Case: OpenID4VP-Login</h3>
        <p>
        <h1>OpenID4VP-Login</h1>
    </a>
  </div>
</div>