export class IssuanceIssueCredential {
    type: string; // issuance for kdk
    oobid: string;
    cPersonalized: { [key: string]: any } | null //credential
    useCre: boolean // true for vdv false for kdk
    sessionId?: string
    version?: string

    constructor(oobid: string, taskIdentifier: string, credentialObj: { [key: string]: any }[] | null, blnUseCre: boolean, sessionId?: string, version?: string) {
        this.type = taskIdentifier;
        this.oobid = oobid;
        this.version = version;
        this.sessionId = sessionId;
        this.cPersonalized = credentialObj;
        this.useCre = blnUseCre;
    }

    getType() {
        return this.type;
    }

    getOobid() {
        return this.oobid;
    }

    getCPersonalized() {
        return this.cPersonalized;
    }

    getUseCredential() {
        return this.useCre;
    }

    toJSON() {
        if (this.sessionId !== undefined && this.version !== undefined) {
            return {
                type: this.type,
                oobid: this.oobid,
                cPersonalized: this.cPersonalized,
                useCre: this.useCre,
                version: this.version,
                sessionId: this.sessionId
            }
        } else {
            return {
                type: this.type,
                oobid: this.oobid,
                cPersonalized: this.cPersonalized,
                useCre: this.useCre
            }
        }
    }

}


export class IssuanceIssueCredentialShortened {
    cPersonalized: { [key: string]: any } | null //credential
    useCre: boolean // true for vdv false for kdk

    constructor(credentialObj: { [key: string]: any }[] | null, blnUseCre: boolean, sessionId?: string, version?: string) {
        this.cPersonalized = credentialObj;
        this.useCre = blnUseCre;
    }

    getCPersonalized() {
        return this.cPersonalized;
    }

    getUseCredential() {
        return this.useCre;
    }

}
